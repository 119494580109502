<template>
    <v-app>
        <v-container>
            <v-row>
                <v-col sm="12" cols="12">
                    <div style="background-color: #fff; padding: 20px 20px 5px;">
                        <div class="function_header">
                            <h2>{{$t('settings')}}</h2>
                            <v-icon
                                onclick="window.history.go(-1); return false;"
                                style="cursor: pointer; color: #333; font-size: 40px;"
                                class="float-right"
                            >close
                            </v-icon>
                        </div>
                        <v-tabs vertical class="tab_setting" slider-color="grayBg" slider-size="7">
                            <v-tab>
                                <span class="hidden-sm-and-up">
                                    <v-icon left>mdi-pen</v-icon>
                                </span>
                                <span class="hidden-sm-and-down text-capitalize">
                                    {{ $t('user_pin') }}
                                </span>
                            </v-tab>
                            

                            <v-tab-item>
                                <v-row>
                                    <v-col sm="12" cols="12" class="pl-6 pt-0">
                                        <UserPin/>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
export default {
    data: () => ({}),
    props: {},
    methods: {
        clickMe(data) {
            // alert(data.link)
            this.$router.push(`${data.link}`);
            //this.$event.target.classList.toggle(active)
            //eslint-disable-next-line no-console
            console.log(data.link)
            //eslint-disable-next-line no-console
            //console.log(data)
        }
    },
    components: {
        UserPin: () => import('./UserPin')
    },
};
</script>
<style scoped>
.v-tab {
    justify-content: left;
    font-size: 16px;
}

.v-tab--active {
    background-color: rgb(255, 255, 255);
}

.tab_setting .v-tab--active {
    font-weight: 700;
    color: #000;
}

.v-tab--active {
    background-color: #ffffff !important;
    border-bottom: 4px solid #92d050;
    border-left: none;
}

p {
    color: rgba(0, 0, 0, 0.87);
}

</style>